import Service from '@/helpers/service'

export default {

    export_work_report(params) {
        return Service().get('work-report/export', {params});
    },    
    import_report(params){
        return Service().post("ReportMultipleImport", params);
    }
}